<template lang="pug">
  app-dialog(v-model="showing" title="Journeys List" @click:outside="clear")
    template(#dialog-action)
      slot(name="activator" :open="open")
        app-button(outlined @click.native="open") Journeys List
    template(#dialog-body)
      journeysList(
        :selectedCity="selectedCity"
        :cities="cities"
        :groupedJourneys="groupedJourneys"
        :loading="loading"
        @needToUpdate="needToUpdateData"
        @update:city="selectCity"
        @enter:end="loadMore"
      )
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import { LOAD_DEFAULT_CITY_ID, SWITCH_FLAG } from '@/util/const.js'
import showingMixin from '@/mixins/showing.mixin'
import errorsMixin from '@/mixins/errors.mixin.js'
import JourneyGrouped from '@/app/admin/modules/course_day/core/courseDayJourneyGrouped'
import PaginationModel from '@/models/paginationModel'
import featureFlagsMixin from '@/mixins/featureFlags.mixin'

export default {
  name: 'JourneyManagement',

  mixins: [showingMixin, errorsMixin, featureFlagsMixin],

  props: {
    disabled: Boolean,
    activeItems: Array
  },

  data: () => ({
    loading: false,
    selectedCity: {},
    groupedJourneys: [],
    pagination: {},
    LOAD_DEFAULT_CITY_ID
  }),

  computed: {
    ...mapGetters({
      _cities: 'crmCourseCities/items',
      journeys: 'crmJourneys/items'
    }),

    cities() {
      return this._cities ? this._cities : []
    }
  },

  methods: {
    ...mapActions({
      loadCities: 'crmCourseCities/list',
      loadJourneys: 'crmJourneys/list',
      loadJourneysMore: 'crmJourneys/loadMore'
    }),

    async selectCity(city) {
      this.selectedCity = city
      this.selectedCity = city
      this.groupedJourneys = []
      this.pagination = new PaginationModel({
        page: 1,
        size: 20
      })
      await this.loadJourneysData()
    },

    async loadJourneysData() {
      await this.loadJourneysList()
      this.groupedJourneys = new JourneyGrouped().groupJourneys(this.journeys)
    },

    async loadJourneysList() {
      try {
        this.loading = true
        let query = {
          city: this.selectedCity.ID,
          page: this.pagination.page,
          size: this.pagination.size
        }
        let res = await this.loadJourneys(query)
        this.loading = false
        this.pagination = new PaginationModel({
          ...res,
          page: res.page,
          size: res.size
        })
        return res
      } catch (error) {
        this.loading = false
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
    },

    async loadMore() {
      try {
        if(this.loading || this.pagination.isLastPage) return
        this.loading = true
        this.pagination.loadMore()
        await this.loadJourneysMore({city: this.selectedCity.ID, page: this.pagination.page, size: this.pagination.size})
        this.groupedJourneys = new JourneyGrouped().groupJourneys(this.journeys)
      } catch (error) {
        this.loading = false
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      } finally { this.loading  = false}
    },

    async loadData() {
      try {
        this.loading = true
        let res = await Promise.all[this.loadCities({cache: true}), this.loadJourneys({city: this.selectedCity.ID})]
        this.loading = false
        return res
      } catch (error) {
        this.loading = false
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
    },

    open() {
      this.updateShowing(true)
    },

    clear() {
      this.updateShowing(false)
    },

    async needToUpdateData(journey) {
      if(journey) {
        let selectedItem = this.groupedJourneys.find(item => item.journeyID === journey.id)

        if(!selectedItem) return
        selectedItem.journeyName = journey.internal_name
        for(let field  in selectedItem.items[0]) {
          if(field in journey )  {
            selectedItem.items[0][field] = journey[field]
          }
        }
        return this.$emit('needToUpdate')
      }
      await this.loadJourneysData()
      this.$emit('needToUpdate')
    }
  },

  watch: {
    showing: {
      handler: function() {
        if(!this.showing) return
        if(!this.cities.length) return
        this.selectCity(this.cities[this.LOAD_DEFAULT_CITY_ID])
      },
      immediate: true
    }
  },

  components: {
    appDialog: () => import('@/components/global/actions/BaseDialog.vue'),
    appButton: () => import('@/components/global/actions/BaseButton.vue'),
    journeysList: () => import('../journey/JourneysList.vue'),
    appSelect: () => import('@/components/global/actions/BaseSelect.vue')
  }
}
</script>
